export const Donate = (props) => {
  return (
    <div id='donate' className='text-center'>
      <div className='container'>
        <div className='col-md-8 col-md-offset-2 section-title'>
          <h2>Donate to the church</h2>
          <h3>Please Donate using our PayPal account below</h3>
          <a href='https://www.paypal.com/donate/?hosted_button_id=ZMPTRV7LLZCTE' target="_blank" rel="noreferrer"><img src='img/donate/Donate.jpg' alt="Donate Sign from PayPal"/>
          </a> <br /> <br /><br />

          {/* <div id="smart-button-container">
            <div id="paypal-button-container"></div>
          </div> */}

          <h4>Alternatively You cal also use the Phone number or email here to donate through Zelle Tel:+1 504 334 9905 or email: nolatewahedo@gmail.com </h4>

        </div>
      </div>
    </div>
  )
}
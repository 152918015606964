export const Links = (props) => {
  return (
    <div id='links' className='text-center'>
      <div className='container'>
        <div className="links-text">
          <div className='section-title'>
            <h2>Links</h2>
            <h3>Explore More about EOTC by using the following links</h3>
            <ul>
              <li> => <a href='http://www.zeorthodox.org/index.htm' target='_blank' rel="noreferrer">
                The Orthodox
              </a>
              </li>
              <li> => <a href=' https://eotcmk.org/e/' target="_blank" rel="noreferrer">
                Mahibere Kidusan
              </a>
              </li>
              <li>  => <a href='https://www.ethiopianorthodox.org/index.html' target=' _blank' rel="noreferrer">
                EOTC Faith and Order
              </a>
              </li>

              <li> => <a href='https://www.youtube.com/watch?v=wSiWLjZyW74'
                target="_blank" rel="noreferrer">
                የቤተክርስቲያን መዝሙሮች
              </a>
              </li>
              <li> => <a href=' https://www.ethiopianorthodox.org/english/calendar.html' target="_blank" rel="noreferrer">
                የቤተክርስቲያን በዓላትና ቀን መቁጠሪያ(Calendar)
              </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div >
  )
}

export const Navigation = (props) => {
  return (
    <nav id='menu' className='navbar navbar-default navbar-fixed-top'>
    <div className='container'>
      <div className='navbar-header'>
        <button
          type='button'
          className='navbar-toggle collapsed'
          data-toggle='collapse'
          // data-bs-toggle='collapse'
          //   data-bs-target='#bs-example-navbar-collapse-1'
          data-spy="scroll"
          data-target='#bs-example-navbar-collapse-1'
          aria-expanded='false'
          aria-label='Toggle navigation'
        >
          {' '}
          <span className='sr-only'>Toggle navigation</span>{' '}
          <span className='icon-bar'></span>{' '}
          <span className='icon-bar'></span>{' '}
          <span className='icon-bar'></span>{' '}
        </button>
        <a className='navbar-brand page-scroll' href='#page-top'>
          Home
        </a>{' '}
      </div>

      <div
        className='collapse navbar-collapse'
        id='bs-example-navbar-collapse-1'
      >
         {/* <ul className='nav navbar-nav  navbar-right me-auto'> */}
        <ul className='navbar-nav navbar-right'>
            <li className="nav-item active">
              <a href='#about' className='page-scroll nav-link' data-spy="scroll" data-toggle='collapse' data-target='.navbar-collapse.show'>
                About
              </a>
            </li>
            <li className="nav-item" >
              <a href='#services' className='page-scroll nav-link' data-toggle='collapse' data-target='.navbar-collapse.show'>
                Services
              </a>
            </li>
            <li className="nav-item">
              <a href='#portfolio' className='page-scroll nav-link' data-toggle='collapse' data-target='.navbar-collapse.show'>
                Gallery
              </a>
            </li>
            <li className="nav-item">
              <a href='#events' className='page-scroll nav-link' data-toggle='collapse' data-target='.navbar-collapse.show'>
                News and Events
              </a>
            </li>
            <li className="nav-item">
              <a href='#donate' className='page-scroll nav-link' data-toggle='collapse' data-target='.navbar-collapse.show'>
                Donate to the Church
              </a>
            </li>
            {/* <li className="nav-item">
              <a href='#member' className='page-scroll nav-link' data-toggle='collapse' data-target='.navbar-collapse.show'>
              Pay Membership Fee
              </a>
            </li> */}
            <li className="nav-item">
              <a href='#links' className='page-scroll nav-link' data-toggle='collapse' data-target='.navbar-collapse.show'>
                Links
              </a>
            </li>
            <li className="nav-item">
              <a href='#contact' className='page-scroll nav-link' data-toggle='collapse' data-target='.navbar-collapse.show'>
                Contact
              </a>
            </li>
        </ul>
      </div>
    </div>
  </nav>
  )
}


export const Zoho = (props) => {
  return (
    <div id='member' className='text-center'>
      <div className='container'>
        <div className='col-md-8 col-md-offset-2 section-title'>
          <h2>Membership Fee</h2>
          <h3>Check Your Monthly Membership Fee Here</h3>
          <a href='https://books.zoho.com/portal/theethiopianorthodoxtewahedoch' target="_blank" rel="noreferrer">
          <button type='submit' className='button'>
          Please use this link to register and check your membership fee!
                </button>

            {/* <img src='img/members/Zoho.jpg' alt="Zoho books"/> */}
          </a>



        </div>
      </div>
    </div>
  )
}
export const Features = (props) => {
  return (
    <div id='features' className='text-center'>
      <div className='container'>
        <div className="feature-text">
          <p>{props.data ? props.data.paragraph : "loading..."}</p>
          <p>{props.data ? props.data.paragraph1 : "loading..."}</p>
        </div>
      </div>
    </div>
  )
}

export const Events = (props) => {
  return (
    <div id='events'>
      <div className='container'>
        <div className='section-title text-center'>
        </div>
        <div className="col-xs-12 col-md-6">
          <div className="events-text">
            <h2>Latest Events and News</h2>
            <p style={{fontSize: 20, fontWeight: 'bold', color: 'red'}}>{props.data ? props.data.paragraph : "loading..."}</p>
            <p style={{fontSize: 20, fontWeight: 'bold', color: 'green'}}>{props.data ? props.data.paragraph1 : "loading..."}</p>
            <p style={{fontSize: 20, fontWeight: 'bold', color: 'red'}}>{props.data ? props.data.paragraph2 : "loading..."}</p>
            <p style={{fontSize: 22, fontWeight: 'bold', color: 'green'}}>{props.data ? props.data.paragraph3 : "loading..."}</p>
            <p>{props.data ? props.data.paragraph4 : "loading..."}</p>
            <p style={{fontSize: 16, fontWeight: 'bold', color: 'green'}}>{props.data ? props.data.paragraph5 : "loading..."}</p>
            <p style={{fontSize: 16, fontWeight: 'bold'}}>{props.data ? props.data.paragraph6 : "loading..."}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-6">
            {" "}
            <img src="img/events/01-large.jpg" className="img-responsive" alt="" />{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

